.aboutPageContainer {
  /* height: 90% !important; */
  /* background-color: rebeccapurple; */
}

.aboutme-header {
  /* background-color: aqua; */
  width: 100%;
  text-align: left;
  font-size: 2.75vmin;
}

.aboutme-body {
  /* background-color: aqua; */
  width: 100%;
  text-align: left;
  font-size: 2.5vmin;
  padding: 1%;
}

.aboutme-picture {
  background-color: #e3d97a;
  max-height: 70vmin;
  max-width: 100%;
}

.aboutme-resume-button-container {
  z-index: 100;
  padding-top: 10%;
}

.aboutme-resume-button {
  padding: 4% 7% 4% 7%;
  border: 2px solid #e3d97a;
  border-radius: 10px;
  font-size: 2vmin;
  cursor: pointer;
  color: #4a1d12;
}

.aboutme-resume-button:hover {
  background-color: #e3d97a;
}
