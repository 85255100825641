.App {
}

h2 {
  font-size: 3vmin;
}

h3 {
  font-size: 2.25vmin;
}

p {
  font-size: 1.75vmin;
}

u {
  font-size: 1.75vmin;
}

b {
  font-size: 1.75vmin;
}

i {
  font-size: 1.75vmin;
}

ol {
  font-size: 1.75vmin;
}
