.scroll-arrow-container {
  position: fixed;
  width: 100%;
  text-align: center;
  bottom: 0;
  left: 0;
  z-index: 99;
}

.scroll-arrow {
  font-size: 15vmin !important;
  cursor: pointer;
}
