.project-information-container {
  width: 95%;
  margin: 0 auto;
  display: flex;
  background-color: #1f2233;
  background-color: white;

  line-height: 1.6;
}

main {
  width: 75%;
  background-color: #161822;
  color: black;
  padding: 3vmin;
  margin-left: 1vmin;
  background-color: white;
}

mobile-main {
  width: 90%;
  background-color: #161822;
  color: black;
  padding: 3vmin;
  margin-left: 1vmin;
  background-color: white;
}
