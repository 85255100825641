.header {
  background-color: #fffdf7;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  /* border-bottom: 0.25vmin solid #e3d97a; */
}

.mobile-header-title {
  font-size: 5vmin;
  color: #e5d96a;
  text-shadow: 1.75px 1.75px #4a1d12;
  font-family: Okta-BoldItalic;
}

.header-yuzu {
  width: 10vmin !important;
  cursor: pointer;
}

.header-button {
  cursor: pointer;
  border-top: 1vmin solid rgb(256, 256, 256, 0);
  padding: 5%;
}

.header-button:hover {
  border-top: 1vmin solid #e3d97a;
}
