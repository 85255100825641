.footer {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background: linear-gradient(180deg, #fffdf7 0% 40%, #e5d96a 40%);
  font-size: 2vmin;
}

.footer-text-wrapper {
  background-color: #e5d96a;
}

.footer-text {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  padding-bottom: 1%;
}
