.overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.1);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 999;
  /* background-color: antiquewhite; */
}

.card-content-container {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: block;
  pointer-events: none;
  z-index: 1;
  /* padding: 40px 0; */
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 999 !important;
  /* background-color: green; */
}

.card-content {
  pointer-events: auto;
  position: relative;
  border-radius: 25px;
  overflow: scroll;
  overflow: hidden;
  width: 100%;
  max-width: 80vw;

  margin: 0 auto;
  height: 90vh;

  top: 6%;
}

.content-container {
  padding: 1%;
  background-color: white;
}
