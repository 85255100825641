.contact-header {
  font-family: Okta-BoldItalic;
}

.contact-button-container {
  padding-top: 10%;
  width: 100% !important;
}

.contact-button {
  padding: 2% 10% 2% 10%;
  background-color: #4a1d12;
  color: white;
  border-radius: 5px;
  font-size: 2vmin;
  cursor: pointer;
}
