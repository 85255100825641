nav {
  padding-right: 1%;
  width: 25% !important;
  height: 100%;
  /* color: black; */
  align-self: flex-start;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 3vmin;
  overflow: auto;
  background-color: white;
  font-size: 1.75vmin;
  overflow-x: hidden;
}

a {
  color: gray;
  text-decoration: none;
}

ul {
  list-style: none;
  width: 100%;
}

ul li::before {
  margin-right: 5px;
  display: inline-block;
  width: 20px;
  height: 20px;
  content: "";
  background-repeat: no-repeat !important;
  background: url(/src//assets//images/baseImages/seed.png);
  background-size: 20px 20px;
}

li.active > a {
  color: #4a1d12;
}

li > a:hover {
  color: #4a1d12;
}

h2,
h3 {
  padding-top: 16px;
  margin-top: -16px;
}
