.homepage-header-title {
  font-size: 8vmin;
  padding-bottom: 2%;
  color: #e5d96a;
  text-shadow: 5px 5px #4a1d12;
  font-family: Okta-BoldItalic;
}

.homepage-mobile-header-title {
  font-size: 8vmin;
  padding-bottom: 2%;
  color: #e5d96a;
  text-shadow: 3px 3px #4a1d12;
  font-family: Okta-BoldItalic;
}

.header-pronunciation {
  font-size: 2.5vmin;
  padding-bottom: 2%;
  padding-left: 10%;
}

.header-breakdown {
  font-size: 3.25vmin;
  padding-bottom: 2%;
}
