.resume-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.1);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 999;
}

.resume-content-container {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: block;
  pointer-events: none;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 999 !important;
}

.resume-content {
  pointer-events: auto;
  position: relative;
  border: 10px solid white;
  overflow: scroll;
  overflow: hidden;
  width: 52.5%;
  margin: 0 auto;
  height: 90vh;
  top: 6%;
}
